/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import url(../scss/fonts.css);

:root {
  --is-main-color: #206D83;
  --is-dark-color: #1a596a;
  --is-black-color: #495057;
  --is-lightbg-color: #f4f5f7;
  --is-white-color: #ffffff;
  --is-yellow-color: #ffb444;
  --is-lightmain-color: #5ba9c7;
  --is-red-color: #f44336;
  --is-green-color: #4caf50;
}
body,html[dir] body {
  background: rgb(32 109 131 / 5%);
  font-family: 'proxima_nova';
  color: var(--is-black-color);
}
.MuiTableCell-root,
.MuiButton-root,
.navigation {
  font-family: 'proxima_nova'!important;
}
input,textarea,select,.form-control,
.react-select .select__control {
  outline: none;
  box-shadow: none;
  border: 1px solid #eee;
  width: 100%;
  border-radius: 4px;
}
input,select,.form-control,
.react-select .select__control {
  height: 35px;
  line-height: 34px;
  padding: 0 10px;
}
.react-select .select__control {
  padding-right: 0;
}
textarea {
  padding: 8px 10px;
}
select {
  cursor: pointer;
}
.navbar-light {
    background: var(--is-main-color) !important;
    height: 60px;
    min-height: 60px;
}

.bg-dark {
    background: var(--is-main-color) !important;   
}

.header-navbar .navbar-container ul.navbar-nav li i.ficon, .header-navbar .navbar-container ul.navbar-nav li svg.ficon {
    height: 1.5rem;
    width: 1.5rem;
    font-size: 1.5rem;
    color: var(--is-white-color) !important;
}

.header-navbar .navbar-container ul.navbar-nav li > a.nav-link {
    color: var(--is-white-color);
    position: relative;
}
.vertical-layout .header-navbar:not(.navbar-horizontal) .navbar-container ul.navbar-nav li.dropdown.dropdown-user .dropdown-menu, .horizontal-layout .header-navbar:not(.navbar-horizontal) .navbar-container ul.navbar-nav li.dropdown.dropdown-user .dropdown-menu {
  top: 55px !important;
}
.main-menu.menu-light .navigation > li .active > a {
    color: var(--is-main-color);
}

[dir=ltr] .main-menu.menu-light .navigation > li ul .active {
    background: linear-gradient(118deg, var(--is-main-color), var(--is-main-color) );
}

[dir] .btn-primary {
    background-color: var(--is-main-color) !important;
    border-color: var(--is-main-color) !important;
}

[dir] .navbar-container .search-input.open {
    background: var(--is-main-color) !important;
    border-radius: 0.5rem;
}
ul {
  padding: 0;
  margin: 0;
}
.main-menu .navbar-header .navbar-brand .brand-logo img {
    max-width: 70px;
    margin-left: -12px;
    margin-top: -19px;
} 
.is-toggle span {
    display: block;
    width: 20px;
    height: 2px;
    background: var(--is-white-color);
    margin-bottom: 4px;
    transition: 0.3s;
  }
.is-toggle span:nth-child(2) {
  width: 12px;
}
.is-toggle {
  cursor: pointer;
  margin-left: 15px;
}
.is-toggle span:nth-child(3) {
  margin-bottom: 0;
}
.is-toggle span:nth-child(2) {
  opacity: 0;
  visibility: hidden;
}
.is-toggle-open .is-toggle span:nth-child(2) {
  opacity: 1;
  visibility: visible;
}
.is-toggle span:nth-child(1) {
  transform: translate(1px, 6px) rotate(-48deg);
}
.is-toggle span:nth-child(3) {
  transform: translate(1px, -6px) rotate(48deg);
}
.is-toggle-open .is-toggle span:nth-child(1),
.is-toggle-open .is-toggle span:nth-child(3) {
  transform: translate(0px, 0px) rotate(0deg);
}
html[dir] .navbar-sticky .app-content {
  padding: calc(0rem + 4.3rem) 1rem 0 2rem;
}
.content-header.row {
  padding-bottom: 10px;
  align-items: center;
  margin: 0 -20px;
  background: #fff;
  padding: 5px;
  border-radius: 4px;
  z-index: 999;
  position: relative;
}

.content-header.row .content-header-left {
  margin-bottom: 0!important;
}
html .content .content-wrapper .content-header-title {
  color: var(--is-main-color);
  float: none!important;
  border: none;
  padding: 0;
  font-size: 20px;
  font-weight: 600;
}
[dir=ltr] .content-header .breadcrumb {
  padding: 0;
}
a,.nav-tabs .nav-link.active {
  color: var(--is-main-color);
}
.breadcrumb-item, .breadcrumb-item a {
  color: var(--is-black-color)!important;
  font-size: 12px;
}
.breadcrumb-item a:hover {
  color: var(--is-main-color)!important;
}
[dir] .nav-tabs .nav-link.active, [dir] .nav-tabs .nav-item.show .nav-link {
  border-bottom: none;
  border-radius: 6px 6px 0 0;
}
[dir=ltr] .nav-tabs .nav-link:after {
  display: none;
}
.tab-content {
  margin: 0;
}
.tab-content .tab-content {
  border: none;
}
.tab-content .MuiPaper-elevation4 {
  box-shadow: none;
}
ul.nav.nav-tabs {
  margin-bottom: 0;
}
.content-wrapper {
  max-width: 100%;
}
ul.nav.nav-tabs a.nav-link.active {
  background-color: var(--is-white-color);
}
ul.nav.nav-tabs a {
  font-weight: 600;
}
.is-head-filter .is-btn-gray {
  outline: none;
  text-transform: capitalize;
  padding-right: 30px;
}
.is-head-filter>ul>li>span.is-btn {
  outline: none;
  text-transform: capitalize;
  font-size: 14px;
  padding-right: 30px;  
  border-radius: 50px;
}
.is-head-filter ul {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
  grid-gap: 10px;
}
.is-head-filter {
  display: flex;
  justify-content: flex-end;
  z-index: 5;
  position: relative;
  text-align: left;
}
thead.MuiTableHead-root tr th,
table .ant-table-thead > tr > th {
  background: var(--is-lightbg-color);
  border-bottom: 1px solid rgb(68 68 70 / 40%);
  padding: 5px 10px;
  color: var(--is-black-color);
  font-size: 12px;
  text-transform: capitalize;
  height: 35px;
  font-weight: 800;
}
.table:not(.table-dark):not(.table-light) thead:not(.table-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.table-dark) th {
  padding-top: 13px;
  padding-bottom: 10px;
  text-align: left;
  background: var(--is-lightbg-color);
  border-bottom: 1px solid rgb(68 68 70 / 40%);
  color: var(--is-black-color);
  font-size: 16px;
  text-transform: capitalize;
}
.is-upload-domain-data {
  text-align: left;
  font-size: 16px;
  height: 48px;
}
thead.MuiTableHead-root th span {
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  color: var(--is-black-color);
}
tbody.MuiTableBody-root tr {
  border-bottom: 1px solid #C7C7C7;
}
tbody.MuiTableBody-root tr td,
table .ant-table-tbody > tr > td {
  font-size: 12px;
  padding: 0px 10px !important;
  height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.openClose {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  justify-content: center;
}

// .ant-table-tbody > tr > td:nth-child(7) {
//   text-align: center;
// }
.ant-table-tbody > tr > td:nth-child(12) {
  text-align: center;
}
// .ant-table-tbody > tr > td:nth-child(6) {
//   text-align: center;
// }

tbody.MuiTableBody-root tr td:nth-child(2) b {
  color: #008FFF;
  font-weight: 600;
  font-size: 14px;
}
.MuiTableCell-paddingCheckbox > div {
  padding: 0;
  justify-content: center;
}
.MuiTableCell-paddingCheckbox > div span.MuiCheckbox-colorPrimary {
  padding: 0;
}
.content-wrapper .is-tab-filter-wrap {
  display: flex;
  align-items: center;
  margin: 0 -20px 15px;
  padding: 5px 20px;
  border-top: 1px solid rgb(243 247 248);
  justify-content: space-between;
  background: #fff;
}
.is-tab-filter-badges {
  display: flex;
  list-style: none;
}
.is-tab-filter-badges li {
  font-weight: 600;
}
.is-tab-filter-badges li:not(:last-child) {
  border-right: 1px solid #cccccc;
  padding-right: 15px;
  margin-right: 15px;
}
.nav-link.active span.is-nav-tab-filter {
  color: var(--is-main-color);
}
.is-tab-filter-wrap a.nav-link.active {
  position: relative;
}
.is-tab-filter-wrap a.nav-link.active:before {
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: var(--is-main-color);
  content: '';
  position: absolute;
}
.tab-content .MuiPaper-elevation4 .MuiPaper-rounded {
  border-radius: 5px;
}
.ant-pagination .ant-pagination-item-active,
.ant-pagination .ant-pagination-item:hover {
  background: #fff;
  border-color: var(--is-main-color);
  color: var(--is-main-color);
}
.ant-pagination .ant-pagination-item:hover a {
  color: var(--is-main-color);
}
.is-btn {
  background: linear-gradient(45deg, var(--is-dark-color), var(--is-dark-color) );
  color: var(--is-white-color);
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 4px;
  padding: 0 15px;
  height: 35px;
  line-height: 35px;
  min-width: 120px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  transition: 0.3s;
  box-shadow: 0px 4px 10px 0 rgb(0 0 0 / 10%);
}
.is-btn-gray {
  background: var(--is-lightbg-color);
  font-size: 14px;
  height: 30px;
  font-weight: 400;
  text-transform: capitalize;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  padding: 0px 10px;
  color: var(--is-black-color);
  line-height: 28px;
  border-radius: 4px;
  border: 1px solid #eee;
  transition: 0.3s;
  cursor: pointer;
  position: relative;
  min-width: 80px;
  margin: 0;
}
.is-btn-disabled {
  border: 1px solid var(--is-lightbg-color);
  background: transparent;
}
.is-btn-circle {
  background: var(--is-lightbg-color);
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.is-btn-sm,
.is-btn-sm:focus,
.is-btn-sm:hover {
  border-radius: 4px;
  padding: 0 15px;
  text-align: center;
  cursor: pointer;
  min-width: auto;
  height: 25px;
  line-height: 23px;
  text-transform: capitalize;
  background: var(--is-lightbg-color)!important;
  border: 1px solid #eee!important;
  color: var(--is-black-color)!important;
  transition: 0.3s;
}
.is-btn-sm:hover {
  box-shadow: 0 8px 25px -8px #82868b;
}
.is-btn:hover,
.is-ocr-uploadmedia-btn input:hover + .is-btn {
  background: linear-gradient(45deg, var(--is-lightmain-color), var(--is-dark-color) );
  transform: scale(1.03);
  color: var(--is-white-color);
}
.is-tab-filter .is-btn {
  height: 30px;
  line-height: 30px;
  min-width: 100px;
}
.is-tab-filter {
  margin-left: 20px;
}
.is-tab-filter-right {
  margin-left: auto;
  z-index: 101;
}
.content-wrapper .nav-tabs .nav-link {
  padding-bottom: 12px;
}
.is-tab-filter .is-btn img {
  margin-right: 5px;
  vertical-align: middle;
}
.is-tab-filter-right ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  grid-gap: 10px;
}
.is-tab-subfilter ul {
  display: flex;
  list-style: none;
  grid-gap: 10px;
  margin: 0;
  padding: 0;
}
.is-tab-subfilter>ul>li>span {
  font-weight: 500;
}
.is-tab-subfilter {
  margin-bottom: 10px;
}
.MuiTableSortLabel-root .MuiTableSortLabel-icon {
  opacity: 0.5;
  margin-left: 0;
}
.MuiButton-label .MuiTableSortLabel-root {
  height: auto;
}
.MuiButton-root:hover {
  background-color: transparent;
}
.is-head-filter ul li {
  position: relative;
}
.is-head-filter>ul>li:after,
.is-spider-batchid h3:after {
  background: url(../../assets/images/down-arrow.svg);
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 20px;
  height: 20px;
  margin: 5px auto;
}
.select__control .select__indicator svg,
.react-select__control .select__indicator svg {
  color: rgb(216, 214, 222)!important;
  width: 15px;
}
.is-head-filter ul li select {
  -webkit-appearance: none;
  -moz-appearance: none;
}
p.MuiTablePagination-caption {
  margin: 0;
} 
.MuiTablePagination-actions button {
  width: 30px;
  height: 30px;
  padding: 0;
  background: var(--is-lightbg-color);
  margin: 0 2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
p.MuiTablePagination-caption, .MuiTablePagination-input {
  background: var(--is-lightbg-color);
  padding: 0 9px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
}
.MuiTablePagination-root .MuiSelect-select:focus {
  background: var(--is-lightbg-color);
}
.MuiMenu-list {
  padding: 0;
  margin: 0;
}
.MuiTablePagination-selectRoot {
  margin-right: 24px;
}
.is-tab-filter-before {
  display: none;
}
.is-filter-modal {
  position: absolute;
  background: #f7f7f7;
  z-index: 1101;
  box-shadow: 0px 0px 20px #00000033;
  border: 0.5px solid #DDDDDD;
  top: 100%;
  width: 665px;
  left: 0;
  cursor: default;
  border-radius: 6px;
  display: none;
}
.is-filter-modal.is-filter-modal-sm {
  width: 370px;
}
.is-tab-subfilter > ul .is-filter-modal.is-panel-open,
.is-filter-modal.is-panel-open,
.is-tab-filter-right ul li:hover .is-filter-modal {
  display: block;
}
.is-filter-modal-head {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  padding: 12px;
  align-items: center;
  border-bottom: 1px solid #DADCE0;
}
.is-tab-subfilter ul li {
  position: relative;
}
.is-tab-subfilter ul li .is-btn,.is-modal-head-content .is-btn {
  text-transform: capitalize;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  min-width: auto;
  border-radius: 50px;
  width: max-content;
  width: -moz-max-content;
}
.is-modal-head-title {
  font-size: 20px;
  font-weight: 500;
  color: var(--is-main-color);
  margin: 0;
}
.is-modal-close {
  color: #707070;
  font-size: 30px;
  line-height: 30px;
  vertical-align: middle;
  cursor: pointer;
}
.is-btn-clear {
  background: var(--is-yellow-color)!important;
  color: var(--is-black-color)!important;
}
.is-modal-select-search {
  background: #EFF1F5;
  padding: 7px 15px;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #DADCE0;
  display: flex;
  grid-gap: 15px;
}
.is-modal-select-search .select__control {
  background: #EFF1F5;
  border: none;
  min-height: auto;
}
.is-modal-select-search .select__value-container {
  padding: 0;
}
.is-modal-select-search .select__multi-value {
  border-radius: 50px;
  border: 1px solid #DADCE0;
  background: var(--is-white-color);
  color: var(--is-black-color);
  line-height: 24px;
  font-size: 12px;
  align-items: center;
  padding-right: 8px;
}
.is-modal-select-search .select__multi-value .select__multi-value__remove {
  width: 13px;
  height: 13px;
  border: 1px solid #DADCE0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}
.is-modal-select-search .select__multi-value .select__multi-value__label,
table .MuiTableCell-body {
  color: var(--is-black-color);
}
.is-modal-select-search .select__multi-value  .select__multi-value__label {
  padding: 0 5px 0 10px;
}
.is-modal-select-search .select__menu {
  text-align: left;
}
.is-modal-select-search .select__input-container {
  margin: 0;
}
.is-filter-modal:after {
  position: absolute;
  content: '';
  left: 60px;
  top: -4px;
  background: #f7f7f7;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
}
.is-filter-modal.is-filter-reverse {
  left: auto;
  right: 0;
}
.is-filter-modal.is-filter-reverse:after {
  left: auto;
  right: 60px;
}
.is-modal-content-wrap {
  margin: 20px;
  background: var(--is-white-color);
}
.is-modal-select-content {
  border: 1px solid #DADCE0;
  border-radius: 4px;
  min-height: 150px;
  background: var(--is-white-color);
  margin-bottom: 50px;
}
.is-tab-filter-right .is-modal-select-list ul {
  display: block;
}
.is-modal-content-wrap.is-modal-select-list {
  padding: 12px;
  margin: 0;
  border-radius: 6px;
}
.is-tab-filter-right .is-modal-select-list ul li {
  padding: 0;
  margin-bottom: 5px;
}
.is-tab-filter-right .is-modal-select-list ul li:last-child {
  margin: 0px;
}
.is-modal-select-list li select {
  padding: 9px;
}
.is-modal-select-list li .is-btn {
  margin-top: 8px;
}
// .vertical-layout.vertical-menu-modern.menu-collapsed .main-menu {
//   width: 260px!important;
// }
.is-sidebar-menuicon {
  position: fixed;
  top: 15px;
  left: 245px;
  background: #fff;
  border-radius: 100%;
  border: 1px solid var(--is-main-color);
  cursor: pointer;
  transition: 0.35s;
  z-index: 5;
  opacity: 0;
}
.is-toggle-open .vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:hover .is-sidebar-menuicon {
  left: 245px;
}
.is-toggle-open .is-sidebar-menuicon {
    border-color: #E8EAED;
    opacity: 1;
}
.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:hover .is-sidebar-menuicon {
  opacity: 1;
}
.is-sidebar-menuicon svg {
    color: var(--is-black-color);
}
.is-toggle-open .is-sidebar-menuicon svg {
    fill: var(--is-main-color);
}
.main-menu.menu-shadow {
  box-shadow: none;
  border-right: 1px solid #E8EAED;
}
.content-wrapper,.vertical-layout.vertical-menu-modern.menu-collapsed .footer {
  margin-left: 245px!important;
  transition: 0.3s;
}
.content-wrapper {
  width: inherit;
}
.app-content, .wrapper.vertical-layout.vertical-menu-modern.menu-collapsed .app-content {
  margin-left: 0;
}
.vertical-layout.vertical-menu-modern.menu-collapsed .navbar.fixed-top {
  left: 0!important;
  z-index: 1051;
}
.main-menu .navbar-header .navbar-brand .brand-logo img {
  max-width: 100%;
}
.is-toggle-open .vertical-layout.vertical-menu-modern.menu-collapsed .main-menu,
.is-toggle-open .main-menu .navbar-header {
  width: 25px!important;
}
.is-toggle-open .content-wrapper,
.is-toggle-open .vertical-layout.vertical-menu-modern.menu-collapsed .footer {
  margin-left: 10px!important;
}
.is-toggle-open .vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:hover {
  width: 260px!important;
}
.is-toggle-open .vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:hover .main-menu-content {
  opacity: 1;
}
.is-toggle-open .main-menu .main-menu-content {
  opacity: 0;
}
.is-toggle-open .is-sidebar-menuicon {
  left: 12px;
}
.is-toggle-open .main-menu .navbar-header .navbar-brand .brand-logo img {
  max-width: 60px;
}
.main-menu.menu-light .navigation > li ul .active {
  box-shadow: none;
}
.tab-pane .MuiButton-text {
  padding: 0px;
}
.main-menu {
  overflow: visible!important;
}
// .is-toggle-open .main-menu.menu-light .navigation > li:not(.open) > ul,
// .vertical-layout .main-menu.expanded .navigation li:not(.open) > ul {
//   display: none!important;
// }
// .main-menu.menu-light .navigation > li ul .sidebar-group-active > a {
//   background: var(--is-dark-color);
//   color: #fff;
// }
// .is-toggle-open .main-menu.menu-light .navigation > li:not(.open) > ul,
// .is-toggle-open .main-menu.menu-light .navigation > li ul {
//   position: absolute;
//   left: 80px;
//   top: 0;
//   background: var(--is-white-color);
//   box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.07);
//   z-index: 1;
//   border-radius: 5px;
// }
// .is-toggle-open .main-menu.menu-light .navigation > li ul ul {
//   left: 98%;
// }
// .is-toggle-open .main-menu.menu-light .navigation > li.open ul:after {
//   position: absolute;
//   top: 14px;
//   content: '';
//   left: -7px;
//   width: 14px;
//   height: 14px;
//   background: #fff;
//   transform: rotate(45deg);
// }
// .is-toggle-open .main-menu.menu-light .navigation > li.open ul ul:after {
//   display: none;
// }
// .is-toggle-open .main-menu.menu-light .navigation > li > ul li.has-sub > a {
//   margin: 7px 10px;
// }
.navigation li.nav-item {
  border-bottom: 1px solid #eee;
}
.main-menu.menu-light .navigation > li.open:not(.menu-item-closing) > a,
.main-menu.menu-light .navigation > li.sidebar-group-active > a {
  background: transparent;
  border-radius: 0px;
}
.main-menu.menu-light .navigation > li:not(.open) > ul .active,
.wrapper .main-menu.menu-light .navigation > li ul .active,
.main-menu.menu-light .navigation > li.active > a {
  background: var(--is-lightbg-color);
  color: var(--is-black-color);
}
.main-menu.menu-light .navigation > li ul .active > a {
  color: var(--is-black-color);
}
.main-menu.menu-light .navigation > li.active > a {
  box-shadow: none;
}
.main-menu.menu-light .navigation li a > .text-truncate {
  padding-right: 10px;
}
.is-toggle-open .vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation li.has-sub>a:after,
.wrapper.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation li.has-sub > a:after {
  right: 25px;
}
.main-menu.menu-light .navigation li a {
  color: var(--is-black-color);
  margin: 0;
}
.is-toggle-open .main-menu .ps__thumb-y {
  width: 0px;
}
.content-wrapper tbody.MuiTableBody-root tr:last-child {
  border-bottom: none;
}
.MuiTableSortLabel-root {
  position: relative;
}
table .MuiTableSortLabel-root:after {
  content: '';
  background: url(../../assets/images/filter-descending.svg);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: 14px;
  width: 14px;
  height: 14px;
  margin: auto;
  opacity: 0.3;
  transition: 0.3s;
}
table [aria-sort="ascending"] .MuiTableSortLabel-root::after {
  background: url(../../assets/images/filter-ascending.svg);
}
table .MuiTableSortLabel-root svg {
  opacity: 0;
  visibility: hidden;
}
.MuiButton-label:hover .MuiTableSortLabel-root:after,
table [aria-sort="ascending"] .MuiTableSortLabel-root::after,
table [aria-sort="descending"] .MuiTableSortLabel-root::after {
  opacity: 1;
}
.is-panel-overlay::before {
  position: fixed;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgb(0 0 0 / 25%);
  z-index: 1100;
  backdrop-filter: blur(2px);
  transition: 0.3s;
}
.is-head-search {
  position: relative;
  width: 100%;
  max-width: 300px;
  margin-left: auto;
  text-align: right;
}
.is-head-search .is-btn-search {
  position: absolute;
  right: 12px;
  top: 8px;
  cursor: pointer;
}
.is-head-search input {
  height: 40px;
  padding-left: 15px;
  padding-right: 50px;
  border-radius: 50px;
  width: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}
span.is-btn-search svg {
  fill: var(--is-white-color);
  width: 16px;
  height: 16px;
}
.is-search-open.is-head-search input {
  width: 100%;
  opacity: 1;
  visibility: visible;
}
.is-search-open span.is-btn-search svg {
  fill: var(--is-black-color);
}
.header-navbar .navbar-container {
  align-items: center;
}
.main-menu.menu-fixed {
  top: 60px;
}
.is-head-client-name {
  margin-left: 20px;
}
.is-head-client-name h2 {
  color: var(--is-white-color);
  font-size: 16px;
  margin: 0;
  text-transform: uppercase;
}
.is-head-user-icon {
  margin-right: 10px;
}
.header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link .user-nav {
  margin-right: 0;
}
.is-ocr-media-btn {
  padding-bottom: 20px;
}
.is-ocr-media-btn {
  text-align: right;
}
.is-vertical-bdr {
  border-right: 1px solid #ccc;
}
.is-ocr-media-btn li {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}
.is-ocr-media-btn li:not(:last-child) {
  margin-right: 10px;
}
.MuiPagination-ul .MuiPaginationItem-page {
  border-radius: 100%;
}
table {
  border: 1px solid var(--is-lightbg-color);
}
.is-ocr-addmedia-table tbody.MuiTableBody-root tr td,
.modal tbody.MuiTableBody-root tr td {
  padding: 6px;
}
.is-ocr-addmedia-table tbody.MuiTableBody-root {
  margin-top: 5px;
}
.is-ocr-addmedia-table tbody.MuiTableBody-root tr,
.modal tbody.MuiTableBody-root tr {
  border: none;
}
.is-ocr-addmedia-table tbody.MuiTableBody-root tr input,
.is-ocr-addmedia-table tbody.MuiTableBody-root tr select,
.modal tbody.MuiTableBody-root tr input,
.modal tbody.MuiTableBody-root tr select {
  padding: 5px;
}
.is-ocr-addmedia-table tbody.MuiTableBody-root tr select {
  min-width: 102px;
}
.is-ocr-addmedia-table tbody.MuiTableBody-root tr:first-child td,
.modal tbody.MuiTableBody-root tr:first-child td {
  padding-top: 12px;
}
.is-ocr-addmedia-table table thead {
  border-top: 1px solid #c7c7c7;
}
ul.MuiPagination-ul {
  justify-content: flex-end;
  padding: 10px 0;
}
.modal .is-btn-right .is-btn {
  margin-top: 15px;
}
.is-btn-right {
  display: flex;
  justify-content: flex-end;
  grid-gap: 9px;
}
::-webkit-scrollbar,.ps__rail-y,
.ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  width: 4px;
  height: 4px;
  background-color: #eee;
}
::-webkit-scrollbar-thumb,
.ps__thumb-y,
.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background: #ccc;
}
::-webkit-scrollbar-thumb:hover,
.ps__thumb-y:hover,
.ps__rail-y:hover > .ps__thumb-y:hover,
.ps__rail-y:focus > .ps__thumb-y:hover,
.ps__rail-y.ps--clicking .ps__thumb-y:hover {
  background: var(--is-dark-color);
}
.wrapper.vertical-layout.vertical-menu-modern .main-menu .navigation > li > a svg {
  color: #5e5873;
  height: 18px;
  width: 18px;
}
.main-menu.menu-light .navigation > li ul li > a {
  padding: 10px 20px 10px 15px;
}
.navigation li a i, .navigation li a svg {
  margin-right: 0.5rem;
}

.is-ocr-uploadmedia-btn {
  position: relative;
}
.is-addmedia-delete svg,
.is-addmedia-delete.is-btn-icon svg {
  fill: var(--is-red-color);
}
.is-addmedia-url svg,
.is-edit-btn svg,
.is-edit-btn.is-btn-icon svg {
  fill: var(--is-green-color);
}
.is-ocr-uploadmedia input {
  width: 100%;
  position: absolute;
  opacity: 0;
  left: 0;
  z-index: 1;
}
.is-ocr-media-btn .is-btn svg {
  margin-right: 5px;
}
h5.modal-title {
  color: var(--is-main-color);
  font-size: 20px;
  font-weight: 600;
}
.modal .modal-header .btn-close,
.modal .modal-header .btn-close:hover {
  transform: translate(0px, 0px);
}
.modal .modal-header {
  background-color: transparent;
  border-bottom: 1px solid var(--is-lightbg-color);
}
.modal .MuiPaper-root {
  box-shadow: none;
}
.modal-body {
  padding: 20px;
}
.modal td .is-btn {
  min-width: max-content;
  min-width: -moz-max-content;
}
.modal-backdrop {
  background-color: rgb(0 0 0 / 28%);
  backdrop-filter: blur(2px);
}
.modal-backdrop.show {
  opacity: 1;
}
.is-ocr-addmedia-table .MuiTableSortLabel-root .MuiTableSortLabel-icon,
.is-ocr-addmedia-table table .MuiTableSortLabel-root:after,
.modal .MuiTableSortLabel-root .MuiTableSortLabel-icon,
.modal table .MuiTableSortLabel-root:after {
  display: none;
}
.MuiToolbar-regular .MuiTypography-h6, .offcanvas-title {
  margin: 0;
  color: var(--is-main-color);
  font-size: 18px;
  font-weight: 600;
  font-family: inherit;
}
.is-card-title {
  color: var(--is-main-color);
  font-size: 18px;
  margin: 0;
}
.offcanvas-title {
  text-transform: capitalize;
}
.card-title {
  margin-bottom: 15px;
}
.MuiToolbar-root[class^="MuiToolbar-root MuiToolbar-regular MUIDataTableToolbar-root-"] {
  padding: 0 0 10px;
  border-bottom: 0;
  min-height: auto;
}
.MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: var(--is-lightbg-color);
}
.MuiToolbar-root .MuiIconButton-root {
  padding: 0;
}
.MuiPopover-paper {
  padding: 15px;
}
.MuiFormControlLabel-root .MuiCheckbox-colorPrimary.Mui-checked,
.MUIDataTableToolbar-icon-22:hover,.MUIDataTableToolbar-iconActive-23 {
  color: var(--is-dark-color)!important;
}
.form-check input.form-check-input {
  padding: 0;
  cursor: pointer;
}
.form-check-primary .form-check-input:checked {
  background-color: var(--is-dark-color);
  border-color: var(--is-dark-color);
}
.form-check-primary .form-check-input:not(:disabled):checked,
.form-check-primary .form-check-input:not(:disabled):focus {
  border-color: var(--is-dark-color);
  box-shadow: 0 2px 4px 0 rgb(32 109 131 / 40%);
}
span.is-tableaction-dot {
  margin-left: 10px;
}
span.is-tableaction-dot {
  width: 25px;
  height: 25px;
  background: var(--is-lightbg-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 100%;
  cursor: pointer;
}
.is-spider-common-btn {
  text-align: right;
  margin-bottom: 15px;
}
.is-btn-icon,.is-btn-delete,
.btn-secondary.is-btn-icon {
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background: var(--is-white-color)!important;
  transition: 0.3s;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  font-size: 12px;
  border: 1px solid #eee!important;
}
.is-btn svg {
  fill: var(--is-white-color);
}
.is-btn-icon svg,
.is-spider-url-column ul li svg,
.is-spider-url-column ul li li svg {
  fill: #758197;
}
.is-btn-delete svg {
  fill: var(--is-red-color);
}
.is-btn-icon:hover,
.btn-secondary.is-btn-icon:focus,
.btn-secondary.is-btn-icon:active,
.btn-secondary.is-btn-icon.active {
  background: var(--is-lightbg-color)!important;
  box-shadow: none!important;
}
.form-label {
  font-size: 16px;
}
.is-repeating-form form {
  margin-bottom: 15px;
  position: relative;
}
.is-bulk-upload .dropzone {
  width: fit-content;
  width: -moz-fit-content;
  margin-left: auto;
}
.is-upload-domain-data {
  margin-top: 20px;
}
.import-component .dropzone {
  min-height: 350px;
}
.progress {
  height: 15px;
}
.is-tab-filter-right .is-btn-gray svg {
  vertical-align: unset;
  margin-right: 2px;
}
div[class^="MUIDataTable-responsiveBase-"] {
  height: calc(100vh - 365px)!important;
  margin-bottom: 10px;
}
.is-repeating-form,
.is-spider-url-column {
  overflow-y: auto;
  height: calc(100vh - 260px)!important;
}
.is-repeat-form-list {
  margin-bottom: 15px;
}
.is-repeat-form-list .import-component {
  display: inline-block;
  vertical-align: bottom;
  margin-left: 5px;
}
.is-repeating-form .is-btn {
  transform-origin: right;
}
.MuiPaper-root[class^="MuiPaper-root MUIDataTableToolbarSelect-root-"] {
  background: var(--is-lightbg-color);
  box-shadow: none;
  margin-bottom: 5px;
  padding: 10px;
}
input:required:focus:invalid {
  border-color: var(--is-red-color);
}
.is-repeat-form-wrap {
  display: flex;
  align-items: flex-end;
  grid-gap: 15px;
}
.is-repeat-form-input {
  flex: 1;
  min-width: 100px;
}
.is-repeat-form-btn {
  max-width: 80px;
}
.is-ocr-media-table .MuiTypography-subtitle1 {
  margin: 0;
  padding: 0;
}
button[class^="MuiButtonBase-root MuiIconButton-root MUIDataTableToolbarSelect-iconButton-"] {
  padding: 0px 0px 0px 5px;
}
.is-addmedia-action {
  display: flex;
  grid-gap: 5px;
  align-items: center;
}
.demo-inline-spacing .is-btn-icon {
  margin: 0;
}
.react-select .select__input-container,
.react-select .select__value-container {
  margin: 0;
  padding: 0;
}
.react-select .select__control {
  min-height: auto;
}
.is-bulkupload-list {
  margin-bottom: 10px;
}
.offcanvas-header .btn-close {
  border: 1px solid var(--is-red-color);
  opacity: 1;
  width: 24px;
  height: 24px;
  background-size: 8px;
  padding: 0!important;
}
.offcanvas-end {
  width: 600px;
}
.offcanvas{
  margin-top: 60px;
}
.offcanvas-backdrop {
  display: none;
}
.offcanvas-header {
  justify-content: space-between;
  border-bottom: 1px solid #eee;
}
.offcanvas-end {
  box-shadow: -3px 0px 20px 1px rgb(0 0 0 / 5%);
}
.is-tag-input {
  display: flex;
  border: 1px solid #eee;
  align-items: center;
  padding: 5px;
  border-radius: 4px;
  height: 35px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-wrap: wrap;
}
.is-tag-input ul {
  display: flex;
  grid-gap: 5px;
  flex-wrap: wrap;
}
.is-tag-input ul li {
  list-style: none;
  background: var(--is-lightbg-color);
  grid-gap: 5px;
  border-radius: 4px;
  padding: 1px 5px;
  display: inline-block;
}
.is-tag-input input {
  border: none;
  height: 20px;
  width: fit-content;
  width: -moz-fit-content;
}
span.tag-close-icon {
  width: 8px;
  display: inline-block;
  cursor: pointer;
  margin-left: 3px;
  color: var(--is-red-color);
}
span.is-nav-tab-filter {
  color: var(--is-black-color);
}
.is-tag-input input::placeholder {
  color: #b9b9c3;
}
.accordion .accordion-header .accordion-button {
  font-weight: 600;
  background: var(--is-lightbg-color);
  box-shadow: none;
  color: var(--is-black-color);
}
.accordion-margin .accordion-item {
  margin-top: 0;
  margin-bottom: 15px;
  box-shadow: none!important;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid var(--is-lightbg-color)!important;
}
.accordion .accordion-header .accordion-button {
  border-radius: 0;
  padding: 10px;
}
.is-accordion-selectdomain .accordion-body table tr th:last-child,
.is-accordion-selectdomain .accordion-body table tr td:last-child {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}
.accordion-body div[class^=MUIDataTable-responsiveBase-],.accordion-body .is-repeating-form {
  height: auto!important;
  overflow-y: unset;
}
.accordion-body .is-repeating-form {
  border-bottom: 1px solid var(--is-lightbg-color);
  margin-bottom: 8px;
  z-index: 101;
  position: relative;
}
.accordion-margin.accordion {
  height: calc(100vh - 280px)!important;
  overflow-y: auto;
}
.is-shrink-spider-infra .accordion-margin.accordion {
  height: calc(100vh - 356px) !important;
}
.is-table-search {
  display: flex;
  justify-content: space-between;
  grid-gap: 5px;
  margin-bottom: 15px;
  position: relative;
  z-index: 101;
  background: var(--is-lightbg-color);
  padding: 5px;
  border-radius: 4px;
  min-height: 45px;
  flex-wrap: wrap;
}
.is-table-search .dayContainer {
  width: 430px;
  min-width: 430px;
  max-width: 430px;
  justify-content: start;
}
.is-table-search  .flatpickr-days {
  width: 430px;
  border: 0;
  justify-content: start;
}
.is-table-search form input {
  padding-left: 30px;
}
.is-table-search form {
  position: relative;
}
.is-table-search form svg {
  position: absolute;
  left: 10px;
  top: 9px;
  fill: var(--is-black-color);
  border-radius: 100%;
}
ul.is-batchid-acc-head {
  display: flex;
  justify-content: space-between;
  width: 95%;
  grid-gap: 3px;
}
ul.is-batchid-acc-head li {
  list-style: none;
  background: #fbfbfb;
  display: inline-block;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: 400;
  line-height: normal;
  border: 1px solid #eee;
  font-size: 14px;
}
.is-infraction-title {
  color: var(--is-red-color);
  margin-bottom: 10px;
}
.is-infraction-content {
  border: 1px solid #eee;
  padding: 15px;
  border-radius: 4px;
  margin-top: 10px;
}
.is-infraction-content p {
  margin-bottom: 0;
}
.accordion .accordion-body {
  padding-top: 1em;
}
.is-preloader-wrap {
  position: relative;
}
// .is-preload-spinner {
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
// }
.is-preloader-wrap[pre-data="flex"] .is-preload-spinner:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
  background: rgb(0 0 0 / 2%);
  backdrop-filter: blur(2px);
  transition: 0.3s;
  border-radius: 4px;
}
.is-preloader-wrap[pre-data="flex"] {
  pointer-events: none;
}
.is-preloader-wrap[pre-data="flex"] .is-preload-spinner:before {
  width: 30px;
  height: 30px;
  border: 3px solid var(--is-dark-color);
  border-right-color: transparent;
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: auto;
  bottom: 30%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  z-index: 2;
  animation: 0.5s preloader linear infinite;
}
@keyframes preloader {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(365deg);
    }
}
.is-table-min-max {
  position: relative;
}
div[class^="MUIDataTableToolbar-actions-"] {
  padding-right: 25px;
}
.is-table-max ul {
  display: flex;
  list-style: none;
  grid-gap: 5px;
  justify-content: flex-end;
}
#is-table-batch-domain .is-table-max ul {
  justify-content: start;
}
.is-table-max svg {
  width: 16px;
  height: 16px;
}
.is-table-max ul li {
  cursor: pointer;
}
.is-domainstatus-pending {
  color: var(--is-yellow-color);
}
.is-domainstatus-done {
  color: var(--is-green-color);
}
.is-domainstatus-process {
  color: var(--is-red-color);
}
.is-shrink-addDomain,
.is-selectDomain-Open .is-shrink-selectDomain,
.is-spider-infra-Open .is-shrink-spider-infra {
  display: none;
}
.is-addDomain-Open .is-shrink-addDomain {
  display: block;
}
.is-table-shrink .col-md-12,
.is-addDomain-Open .col-md-12 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.is-spider-infra-Open .col-md-4 {
  flex: 0 0 auto;
  width: 50%;
}
.is-batchid-dropdown,
.is-table-search form {
  max-width: 180px;
}
.is-batchid-dropdown,
.is-table-search form,
.is-selectDomain-Open .col-md-7 {
  width: 100%;
}
.is-table-search >* {
  flex: auto;
}
.is-table-filter {
  display: flex;
  grid-gap: 15px;
  justify-content: flex-end;
}
.css-tlfecz-indicatorContainer {
  padding: 0 10px 0 0px;
}
.is-btn-icon {
  position: relative;
}
.is-toggle-icon {
  position: absolute;
  top: -30px;
  background: var(--is-black-color);
  white-space: nowrap;
  margin: auto;
  color: #fff;
  padding: 7px 10px;
  border-radius: 4px;
  left: 0;
  font-size: 12px;
  z-index: 1;
  width: fit-content;
  width: -moz-fit-content;
  line-height: normal;
}
.is-toggle-icon:after {
  position: absolute;
  left: 14px;
  bottom: -3px;
  background: var(--is-black-color);
  width: 7px;
  height: 7px;
  content: '';
  transform: rotate(45deg);
}
.is-toggle-icon,
svg.is-toggle-hide,
.is-addDomain-Open .is-toggle-show,
.is-spider-infra-Open .is-toggle-show,
.is-selectDomain-Open .is-toggle-show {
  display: none;
}
.is-btn-icon:hover span.is-toggle-show,
.is-addDomain-Open .is-btn-icon:hover span.is-toggle-hide,
.is-spider-infra-Open .is-btn-icon:hover span.is-toggle-hide,
.is-addDomain-Open svg.is-toggle-hide,
.is-spider-infra-Open svg.is-toggle-hide,
.is-selectDomain-Open svg.is-toggle-hide,
.is-selectDomain-Open .is-btn-icon:hover span.is-toggle-hide {
  display: block;
}
.is-urlcount-wrap ul {
  display: flex;
  list-style: none;
  grid-gap: 5px;
}
tbody.MuiTableBody-root tr:hover .is-btn-icon {
  background: var(--is-white-color)!important;
}
.is-DeleteEditIcon {
  display: flex;
  grid-gap: 5px;
}
.is-domain-list-delete {
  text-align: center;
}
.is-delete-confirm-modal .is-btn-right {
  justify-content: center;
  margin-top: 8px;
}
.is-delete-confirm-modal .modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.is-domain-list-delete h2 {
  font-size: 26px;
  margin-bottom: 5px;
  font-weight: 600;
}
.is-domain-list-delete p {
  margin: 0;
}
.is-delete-confirm-modal .modal-body {
  padding-top: 0;
  padding-bottom: 30px;
}
.is-domain-list-delete svg {
  width: 60px;
  height: 60px;
  fill: var(--is-red-color);
  margin-bottom: 20px;
}
.is-spider-batchid h3 {
  border: 1px solid #eee;
  border-radius: 4px;
  height: 35px;
  line-height: 33px;
  font-size: 14px;
  padding: 0 10px;
  cursor: pointer;
  margin: 0;
  color: var(--is-dark-color);
  font-weight: 600;
}
.is-spider-batchid {
  position: relative;
}
.is-spider-batchid table,
.is-batch-review-incident table {
  box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
  background: var(--is-white-color);
  border-radius: 4px;
  position: absolute;
  right: 0;
  top: auto;
  width: max-content;
  border: 1px solid #eee;
  display: none;
}
.is-spider-batchid-open.is-spider-batchid table,
.is-create-incident-open.is-batch-review-incident table {
  display: table;
}
.is-spider-batchid table tr>* {
  padding: 8px 10px;
}
.is-spider-batchid table tr {
  border-bottom: 1px solid #eee;
}
.is-spider-batchid table tr>*:not(:nth-child(2)) {
  text-align: center;
}
.is-spider-table-tag {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50px;
}
.is-pending-batch-tab ul {
  display: flex;
  grid-gap: 5px;
  justify-content: center;
  list-style: none;
  cursor: progress;
}
.is-pending-batch-tab {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}
.is-pending-batch-tab ul li {
  position: relative;
}
.is-pending-batch-tab ul li img {
  width: 24px;
  height: 24px;
  border-radius: 100%;
}
.is-pending-batch-tab ul li span {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.is-urlcount-wrap .is-btn-icon {
  width: auto;
  min-width: 28px;
}
.is-batch-infraction-review {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}
.is-batch-review p {
  margin: 0;
}
.is-batch-review {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}
.is-batch-review .react-select {
  flex: auto;
}
.is-batch-review-incident {
  text-align: right;
  position: relative;
  z-index: 101;
}
.is-batch-review-incident .is-btn-gray {
  font-weight: 600;
}
.is-batch-review-incident table {
  text-align: left;
  width: 100%;
  min-width: 400px;
}
.is-batch-review-incident table tr>* {
  padding: 15px;
  width: 50%;
  max-width: 200px;
}
.is-batch-review-incident table th {
  background: var(--is-lightbg-color);
}
.is-modal-content-wrap .select__clear-indicator {
  text-transform: capitalize;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  min-width: auto;
  width: max-content;
  width: -moz-max-content;
  border-radius: 50px;
  background: var(--is-yellow-color);
  padding: 0 15px;
  text-align: center;
  cursor: pointer;
  border: none;
  box-shadow: 0px 4px 10px 0 rgb(0 0 0 / 10%);
  color: var(--is-black-color);
  display: inline-flex;
  align-items: center;
  grid-gap: 5px;
  position: absolute;
  top: -71px;
  right: 77px;
}
.is-modal-content-wrap .select__clear-indicator:after {
  content: 'Clear Filter';
}
.is-modal-content-wrap .select__clear-indicator:before {
  background: url(../../assets/images/filter.svg);
  content: '';
  background-size: 14px;
  position: absolute;
  width: 14px;
  height: 14px;
}
.is-modal-content-wrap .select__clear-indicator svg {
  opacity: 0;
}
.is-modal-content-wrap .select__clear-indicator:hover {
  color: inherit;
}
.is-spider-url-column ul li,
.is-spider-url-column ul li li {
  list-style: none;
  position: relative;
}
.is-spider-url-column>ul>li>ul {
  display: flex;
  grid-gap: 15px;
  margin-bottom: 15px;
}
.is-spider-url-column>ul>li>ul>li:not(:last-child) {
  border-right: 1px solid #eee;
  padding-right: 15px;
}
.is-spider-url-column>ul>li {
  max-width: 99.5%;
}
.is-spider-url-column ul li h2 {
  font-size: 14px;
  font-weight: 600;
  color: var(--is-black-color);
  margin-bottom: 0;
}
.is-spider-url-column>ul>li:not(:last-child) {
  margin-bottom: 20px;
}
.is-media-found-wrap {
  position: relative;
  width: fit-content;
  width: -moz-fit-content;
}
.is-media-found {
  box-shadow: 0 5px 25px rgb(34 41 47 / 10%);
  background: var(--is-white-color);
  border-radius: 4px;
  border: 1px solid #eee;
  padding: 10px;
  position: absolute;
  width: 290px;
  right: 0;
  display: none;
  z-index: 1;
}
.is-media-found-wrap:hover .is-media-found {
  display: block;
}
.is-media-found li {
  padding: 2px 0;
  font-size: 14px;
}
.is-media-found p {
  margin-bottom: 0;
}
.is-spider-url-column .MuiTableFooter-root,
.is-spider-url-column ul li .MuiInput-underline:before,
.is-spider-url-column ul li .MuiInput-underline:after,
.is-spider-url-column ul li .MuiIconButton-root,
.is-spider-url-column .MuiTableSortLabel-root,
#is-table-spider-domain .MuiTableSortLabel-root {
  display: none;
}
.is-spider-url-column div[class^=MUIDataTable-responsiveBase-] {
  height: auto !important;
}
.is-spider-url-column ul li li span {
  padding-left: 5px;
  vertical-align: middle;
}
.is-spider-url-column ul .is-spider-url-done svg,
.is-spider-url-done span {
  fill: var(--is-green-color);
  color: var(--is-green-color);
}
.is-spider-url-column ul li div[class^=MUIDataTable-responsiveBase-] {
  max-height: 255px;
  overflow-y: auto;
}
.is-spider-url-column ul li .MuiToolbar-gutters {
  position: absolute;
  top: -5px;
  right: 0;
  display: block;
  padding: 0;
}
.is-spider-url-column ul li .MuiInputBase-input {
  height: 32px;
  line-height: 30px;
  padding-left: 30px;
}
.is-spider-url-column .MuiToolbar-gutters svg {
  margin-top: 7px;
  position: absolute;
  left: 8px;
  color: #ccc;
}
.is-spider-url-column ul li .MuiFormControl-fullWidth {
  flex: 1;
  max-width: 200px;
}
.is-batch-accordian .accordion-margin .accordion-item {
  border: none;
}
.is-batch-accordian ul.is-batchid-acc-head li {
  background: none;
  padding: 2px 20px 2px 10px;
  border-radius: 0px;
  border: none;
  border-right: 1px solid #ddd;
}
.is-batch-accordian .accordion .accordion-header .accordion-button {
  padding: 5px;
}
.is-table-search .is-infraction-title {
  margin-bottom: 0px;
  flex: 100%;
}
.is-table-search {
  align-items: center;
}
table .is-btn-icon {
  width: 22px;
  height: 22px;
  line-height: initial;
}
.is-url-count-bg {
  background: rgba(244, 106, 106, 0.12)!important;
}
.is-url-in-process-bg {
  background: rgba(52, 195, 143, 0.12) !important;
}
.is-infraction-bg {
  background: rgba(241, 180, 76, 0.12) !important;
}
.is-media-bg {
  background: rgba(80, 165, 241, 0.12) !important;
}
.is-counts-badges {
  display: flex;
  grid-gap: 3px;
  list-style: none;
}
.is-counts-badges li span {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
}
.is-card-body {
  background: var(--is-white-color);
  border-radius: 4px;
  padding: 15px!important;
  box-shadow: 0 0 8px 0 rgb(18 38 63 / 3%);
  height: calc(100% - 0px);
}

// tuesday
.is-filed-scroll-hide .select__control{
  overflow-y: hidden;
}
.react-select .select__clear-indicator {
  display: none;
}
// end

.ant-btn.ant-btn-primary,
.ant-checkbox-checked .ant-checkbox-inner {
  border-color: var(--is-main-color)!important;
  background: var(--is-main-color)!important;
}
.ant-btn-link, .ant-btn-link:hover, .ant-btn-link:focus,
.ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected,
.ant-table-filter-trigger.active,.ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active,
.ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
  color: var(--is-main-color)!important;
}
.ant-checkbox-checked::after,
.ant-pagination-prev:hover button, .ant-pagination-next:hover button {
  border-color: var(--is-main-color)!important;
}
.ant-pagination-prev button, .ant-pagination-next button {
  display: flex!important;
  justify-content: center;
  align-items: center;
}
.is-config-row {
  position: relative;
}
input[type="checkbox"] {
  padding: 0;
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.is-config-row .is-config-row-btn {
  position: absolute;
  right: 4px;
  top: 4px;
}
.is-config-row-hidden,
.is-config-row-btn input:checked ~ .is-config-row-visible {
  display: none;
}
.is-config-row-btn input:checked ~ .is-config-row-hidden {
  display: inline-block;
  background: #84a4ad;
}
.is-config-row-btn input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  top: 0;
  margin: auto;
}
.is-config-row-btn .is-btn {
  height: 24px;
  line-height: 24px;
  min-width: 80px;
  font-size: 12px;
  text-transform: capitalize;
}
.is-config-row span {
  position: absolute;
  left: 10px;
  top: 8px;
}
.is-config-row input.input-item {
  padding-left: 25px;
}
.is-config-wrap .is-card-body {
  height: calc(100% - 0px);
}
.row-dragging {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.row-dragging .drag-visible {
  visibility: visible;
}
ol,ul,dl {
  margin-top: 0;
  margin-bottom: 0em !important;
}
.is-config-row {
  position: relative;
}
input[type="checkbox"] {
  padding: 0;
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.is-config-row .is-config-row-btn {
  position: absolute;
  right: 4px;
  top: 4px;
}
.is-config-row-hidden,
.is-config-row-btn input:checked ~ .is-config-row-visible {
  display: none;
}
.is-config-row-btn input:checked ~ .is-config-row-hidden {
  display: inline-block;
  background: #84a4ad;
}
.is-config-row-btn input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  top: 0;
  margin: auto;
}
.is-config-row-btn .is-btn {
  height: 24px;
  line-height: 24px;
  min-width: 80px;
  font-size: 12px;
  text-transform: capitalize;
}
.is-config-row span {
  position: absolute;
  left: 10px;
  top: 8px;
}
.is-config-row input.input-item {
  padding-left: 25px;
}
.is-config-wrap .is-card-body {
  height: calc(100% - 0px);
}
.is-status-badge {
  background: var(--is-green-color);
  padding: 2px 8px;
  border-radius: 30px;
  color: var(--is-white-color);
}
.ant-switch {
  background: var(--is-lightbg-color);
  background-image: none!important;
}
td.ant-table-cell ul {
  list-style: none;
}
.openClose .in-open-btn {
  border: 1px solid var(--is-lightbg-color);
  border-radius: 50px;
  // padding: 0px 8px;
  font-size: 12px;
  color: var(--is-green-color);
  display: inline-block;
}
.openClose .in-close-btn {
  border: 1px solid var(--is-lightbg-color);
  border-radius: 50px;
  padding: 0px 8px;
  font-size: 12px;
  color: gray;
  display: inline-block;
  padding-left: 2px;
}

.openClose .badge-left {
  color: var(--is-white-color);
  background: var(--is-green-color) !important;
  font-size: 10px;
  padding: 4px 6px;
  display: inline-block;
  vertical-align: text-top;
  margin-top: -6px;
  margin-left: -6px;
  margin-right: 3px;
}
.openClose .badge-right {
  color: var(--is-white-color);
  background: gray !important;
  font-size: 10px;
  padding: 4px 6px;
  display: inline-block;
  vertical-align: text-top;
  margin-top: -6px;
  margin-left: -15px;
}

.form-switch {
  padding-left: 5 !important;
} 
::-webkit-validation-bubble-message {
  display: none;
}
.is-card-drag .ant-table-content td:last-child,
.is-card-drag .ant-table-content tr:first-child th:last-child {
  text-align: right;
}
.ant-table-wrapper .ant-switch-small:not(.ant-switch-checked) {
  background: #b1dbeb;
}
.ant-table-wrapper .ant-switch-checked {
  background: var(--is-main-color);
}
tr.row-dragging {
  background-color: var(--is-main-color);
  color: var(--is-white-color);
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items:center;
  justify-content:space-between;
}
tr.row-dragging .ant-table-cell svg {
  fill: var(--is-white-color);
  opacity: 1;
}
.w-35 {
  width: 35%;
}
.w-40 {
  width: 40%;
}
.ck.ck-editor__main .ck-editor__editable_inline {
  min-height: 100px;
}
.css-6j8wv5-Input{
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.css-319lph-ValueContainer{
  padding-top: 0;
  padding-bottom: 0;
}
.css-1s2u09g-control{
  min-height: 35px !important;
}
.css-1s2u09g-control .css-g1d714-ValueContainer {
  overflow-y: auto;
  padding: 0px 8px;
  max-height: 30px;
}
.css-1pahdxg-control{
  border: 0;
  border-color: transparent;
  height: 35px;
  overflow-y: auto;
}
.offcanvas-body .css-1s2u09g-control > div:first-child {
  height: 35px;
  overflow-y: auto !important;
}
.css-1pahdxg-control:hover{
  border-color: transparent;
  border-width: 0; 
  min-height: 35px !important; 
}
.css-1pahdxg-control:focus{
  min-height: 35px !important;
}
.css-1f43avz-a11yText-A11yText {
  min-height: 0px;
}
.is-marker-remove li{
  list-style: none;
}
.offcanvas-body .is-marker-remove li#AddcaseTooltip {
  margin-top: 37px;
  margin-left: 5px;
}
.offcanvas-body .is-marker-remove li#AddcaseTooltip {
  margin-top: 37px;
  margin-left: 5px;
}
.offcanvas-body .is-marker-remove span#DeleteToggle {
  margin-top: 34px;
  margin-left: 9px;
}
.bannedTerm-box {
  border: solid 1px #eee;
  background: #f6f6f6;
  margin-bottom: 10px;
  padding: 10px 15px 4px 15px;
}
.offcanvas-body textarea.form-control {
  min-height: 80px;
}
.select__control .select__value-container--is-multi .select__multi-value {
  margin: 2px 3px;
}
.select__control .select__value-container--is-multi .select__input-container {
  display: block;
  // height: 32px;
  margin: 0;
  padding: 0;
}
.select__dropdown-indicator {
  padding: 0;
}
.select__control .select__value-container--is-multi {
  max-height: 32px;
  overflow: auto;
  padding-right: 28px;
  padding-top: 0;
  padding-bottom: 0;
}
.select__control.css-k3ockt-control,
.select__control.select__control--is-focused {
  min-height: 35px;
}
.select__control .select__value-container--is-multi ~ .select__indicators {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}
.match-height {
  > [class*='col'] {
    display: flex;
    flex-flow: column;

    > .card {
      flex: 1 1 auto;
    }
  }
}
.react-select .select__control {
  overflow-y: hidden;
}
.select__input-container  .select__input,
.select__value-container--is-multi .select__input-container {
  height: 25px;
}
.is-font-small{
  font-size: 12px;
}
.is-ocr-media-table ul li a {
  font-size: 12px;
}
.is-card-body .ant-spin-nested-loading ul li a {
  font-size: 12px;
}
.is-DeleteEditIcon .is-view-btn {
  width: 50px;
  background: #ebe9f1 !important;
}
.is-advanced-filter form{
  max-width: 100%;
  width: 100%;
}
.is-filter-tabs{
  gap: 10px;
  padding-top: 2px;
}
.is-ctab-list ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  cursor: pointer;
  overflow-x: auto;
  white-space: nowrap;
}
.is-ctab-list ul li {
  position: relative;
  padding: 4px 20px;
  border-radius: 4px 4px 0 0;
}
.is-ctab-list ul li.active {
  background: var(--is-lightbg-color);
}
.is-ctab-data {
  border: 1px solid #eee;
  padding: 20px;
}
.ck.ck-editor {
  z-index: 0;
}
.roll-permision .selction .select__control {
  min-width: 112px;
  text-align: left;
  min-height: 30px;
}
.ant-table.ant-table-small .ant-table-selection-column {
  padding: 0 5px!important;
}
.is-ant-table-icon {
  margin: 4px 0;
}
.is-batchdomain-table .ant-table-body {
  max-height: 150px!important;
}
.card .card-header {
  padding: 0;
}
.card .card-header .card-title {
  font-size: 20px;
  background: var(--is-lightbg-color);
  padding: 20px 30px;
  display: block;
  width: 100%;
  color: var(--is-main-color);
  font-weight: 600;
  border-radius: 0.428rem 0.428rem 0 0;
}
.card .card-header > div {
  flex: auto;
}
.card-header .card-subtitle {
  padding: 20px 30px;
  display: block;
  width: 100%;
}
.card-header .card-subtitle h1 {
  font-size: 20px;
}
.card-body .apexcharts-legend-series {
  margin: 10px 5px !important;
}
.card-body .apexcharts-legend-series .apexcharts-legend-text {
  font-size: 16px!important;
  margin-left: 5px;
}
.react-select .select__control {
  background: var(--is-white-color);
}
// .ant-pagination li {
//   display: none;
// }
.ant-pagination .anticon svg {
  width: 16px;
  height: 16px;
}
.ant-pagination button {
  background: var(--is-lightbg-color) !important;
}
.ant-pagination li.ant-pagination-prev,
.ant-pagination li.ant-pagination-next {
  display: block;
  min-width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
}
.ant-pagination li.ant-pagination-next {
  margin-left: 5px !important;
}
.is-banned-term .css-1s2u09g-control,
.is-banned-term .css-1s2u09g-control:hover,
.is-banned-term .css-g1d714-ValueContainer,
.is-banned-term .css-1s2u09g-control:focus,
.is-banned-term .css-1pahdxg-control:focus,
.is-banned-term .css-1pahdxg-control:hover,
.is-banned-term .css-1pahdxg-control {
  height: 100%;
  max-height: 200px;
}
.is-banned-term .css-1pahdxg-control {
  box-shadow: none;
  border: 1px solid #eee;
}
.offcanvas-body .input-group .form-control.is-invalid {
  z-index: auto;
}
.is-batchid-dropdown .react-select {
  z-index: 3;
}
.swal2-container .swal2-title {
  font-size: 20px;
}
td.ant-table-cell .is-btn-circle svg {
  width: 10px;
  height: 10px;
}
td.ant-table-cell .is-btn-circle {
  margin: 4px 2px;
  width: 24px;
  height: 24px;
  padding: 4px !important;
  border-radius: 3px;
}
.ant-table .ant-table-thead > tr > th {
  text-transform: uppercase;
  z-index: 0;
}
.is-banned-term {
  overflow: hidden;
}
.is-banned-term .css-12jo7m5 {
  white-space: unset;
  word-break: break-word;
}
.ant-table-cell-ellipsis .ant-table-column-title {
  text-overflow: unset !important;
  overflow: unset !important;
}

.select__multi-value .select__multi-value__label {
  line-height: normal;
}
.select__control .select__multi-value {
  margin-top: -4px;
  background: var(--is-main-color);
}

[data-isopen="display: none"] {
  display: none;
}
.apply-btn { 
  padding: 4px 17px;
  background-color: #1b596a !important;
  color: #ffff;
  border-radius: 7px;
  border: none;
}
.Total-Count-table {
  margin-top: -28px;
  margin-bottom: 0.5em;
}
// Start Compose Mail
.compose-form {
  padding-top: 10px;
}
.compose-form .compose-mail-form-field {
  padding: 5px 0px;
  border-bottom: 1px solid rgba(34,41,47,.05);
  display: flex;
  align-items: center;
}
.compose-form .compose-mail-form-field:first-child {
  border-top: 1px solid rgba(34,41,47,.05);
}
.compose-form .composeTo-btns {
  padding-left: 10px;
}
.compose-form .compose-mail-form-field .select__control {
  border: none;
  border-radius: 0;
}
.compose-form .compose-mail-form-field .form-label {
  margin-bottom: -3px;
}

// End Compose Mail
// country flag css start---
.country-flag .PhoneInputCountry {
  display: flex;
  align-items: center;
  width: 30%;
}
.country-flag .PhoneInputCountryIcon {
  width: 80px;
  margin-left: 10px;
  // margin-bottom: 8px;
}
// .country-flag .PhoneInputCountry{
//   width: 18%;
//   position: absolute;
// }
.country-flag .PhoneInput{
  position: relative;
}
.country-flag .PhoneInputCountry {
  position: absolute;
  width: 146px;
}
.country-flag .PhoneInputInput {
  padding-left: 160px;
}
// .country-flag .PhoneInputCountryIcon {
//   width: 70px;
//   float: left;
//   margin-right: 10px;
//   margin-top: 5px;
// }
// .country-flag .PhoneInputInput {
//   width: calc(100% - 80px);
//   margin-top: 10px;
// }
// country flag css end---

.compose-footer-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}
.compose-mail-form-field input {
  border: none !important;
}
.in-mail-design {
  border: 1px solid #f8f8f8;
  padding: 0px 0 15px;
  border-radius: 6px 6px;
}
.is-mail-sub {
  background: #f4f5f7;
}
.rdw-editor-wrapper .rdw-editor-toolbar {
  border-color: #ebe9f1;
  border-left: 0;
  border-right: 0;
}
.is-compose-mail {
  font-size: 16px !important;
  padding: 8px 15px;
  // background: #f4f5f7;
  margin-bottom: 10px !important;
  border-radius: 6px 6px 0 0;
}
// .is-vlog-tab li {
//   // background: #f1f1f1;
// } 
.is-zindex-send{
  display: inline-block;
  position: relative;
  z-index: 1;
}
.is-email-attach{
  margin-top: -40px;
}
.is-email-attach label.form-label.form-label {
  width: 100%;
}
.css-1s2u09g-control, 
.css-1s2u09g-control:hover {
  border-color: #eee;
  height: 35px;
}
.css-1s2u09g-control:focus,
.css-1pahdxg-control:focus,
.css-1pahdxg-control:hover {
  border-color: #206D83;
  height: 35px;
}
.css-1pahdxg-control {
  box-shadow: 0 0 0 1px #206D83;
  height: 35px;
}
// email template css start-----
.edit-case {
  background: #f4f5f7;
}
.send-mail .is-tab-filter-wrap {
  margin: 0 -17px;
  // background-color:#f4f5f7 ;
 
}
.compose-footer-wrapper .send .btn-primary {
  border-radius: 5px !important;
}
// email attechment css start -------
.nav-tabs .nav-link:first-child{
  padding-left: 0;
}
h5.modal-title{
  font-size: 18px;
}
.is-border-remove{
  flex: 1;
}
.compose-mail-form-field .send{
  margin-bottom: 4px;
}
.email-temp{
  margin-bottom: 3px;
}
// email attechment css end -------

// email template css end-----

// for attachment css start--------
.compose-footer-wrapper .card-body{
  padding: 8px 0px;
}
.compose-footer-wrapper .card-body p{
  margin-bottom: 0;
}
.compose-footer-wrapper .card-body p a{
  font-size: 14px;
}
.compose-footer-wrapper .card-body .dropzone .gap{
  grid-gap:6px;
}
.compose-footer-wrapper .email-attachement .card{
  margin-bottom: 0;
}
.is-box-shadow-romove {
  box-shadow: none !important;
}
.ck.ck-editor {
  z-index: 0;
  position: relative;
  margin-bottom: 10px;
}

.modal-body a[title="URL"] {
  word-break: break-word;
}

.is-box-shadow-romove .list-group-item {
  padding: 5px;
}

.is-box-shadow-romove .list-group-item p {
  font-size: 12px;
  line-height: 14px;
}

.is-box-shadow-romove .list-group-item svg {
  width: 16px;
  height: 16px;
  margin-right: 0;
}

.is-box-shadow-romove .list-group-item .file-preview.me-1 {
  margin-right: 8px !important;
}

 .list-btn-email{
  padding: 2px !important;
}
.is-box-shadow-romove .dropzone {
  width: 100%;
  max-width: 80px;
  float: right;
  display: block;
  min-height: auto;
}
.is-box-shadow-romove .list-group {
  width: 100%;
  margin-top: 35px !important;
}
.is-box-shadow-romove .list-group-item:last-child {
  margin-bottom: 20px;
}
.is-send-msg{
  position: relative;
  margin-top: -50px;
}
// for attachment css end--------

// .offcanvas-body .ant-table-thead {
//   position: sticky;
//   top: 0;
//   z-index: 4;
// }
.is-head-filter .select__control.css-k3ockt-control {
  min-height: 30px;
  height: 30px;
  background: #f3f4f6;
}
.css-g1d714-ValueContainer .css-1rhbuit-multiValue {
  min-width: 50px;
  justify-content: space-between;
}

.ant-pagination-options {
  display: block !important;
  margin-top: 4px;
  margin-left: 5px !important;
}
// .ant-table table {
//   width: 100%;
// }

/* capitalize Later in Table*/
// td{
//   text-transform: capitalize;
// }
.is-card-bodyDomain {
  background: var(--is-white-color);
  border-radius: 4px;
  padding: 15px!important;
  box-shadow: 0 0 8px 0 rgb(18 38 63 / 3%);
  height: calc(80% - 0px);
}
// dashboard btn css start======
.dasboard-btn {
  padding: 10px;
  border-radius: 5px;
  margin-right: 5px;
}
.case-btn{
  padding: 12px 9px;
}
.domain.case-btn{
  padding: 12px 9px;
  width: 90px;
}
// dashboard btn css end======

.offcanvas.w-70 {
  width: 60%;
}

[dir] .dasboard-btn {
  padding: 7px !important;
  border-radius: 5px;
}
.react-select-container {
  background-color: 'red';
}

// And section css start------
.and-form .and-btn{
  height: 32px;
}
// And section css end------

.is-modal-dateicon {
  position: relative;
}
.is-modal-dateicon img {
  width: 14px;
  position: absolute;
  right: 22px;
  bottom: 8px;
  opacity: 0.6;
}
.is-tab-filter-wrap .form-check-label .form-check-input {
  width: 12px;
  height: 12px;
  margin-right: 10px;
  margin-top: -3px;
}
.is-tab-filter-wrap .form-check-label {
  display: flex;
  align-items: center;
}

// ctm search box css start=========
.ctm-search-box .is-table-search{
  background: none;
}
.ctm-search-box .is-table-filter{
  box-shadow: 0 5px 30px -12px rgba(58,63,69,.219);
}
.ctm-search-box .is-table-filter form{
  max-width:100% ;
}
.ctm-search-box .is-table-search form span.cancel-btn {
  position: absolute;
  right: 35px;
}
// ctm search box css end=========

.table-filter-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table-filter-wrap > div {
  margin-bottom: 10px;
}
form.table-search-form {
  position: relative;
  width: 360px;
}
.table-search-form svg.search-icon {
  position: absolute;
  left: 9px;
  top: 10px;
}
form.table-search-form .cancel-btn {
  position: absolute;
  right: 8px;
  top: 4px;
}
form.table-search-form input {
  padding-right: 25px;
  padding-left: 32px;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}
// ssr content start
.batch-wrapper{
  padding: 6px 19px 0 19px !important;
  // padding: 0 15px!important;
}
.batch-wrapper .batch-header {
  background: #f4f5f7;
  padding: 5px 0;
  margin-bottom: 10px;
}
.batch-wrapper .batch-header h3{
  margin: 0;
}
.batch-body h4 {
  color: var(--is-black-color);
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.batch-body p {
  font-size: 12px;
}
// ssr content end
// responsive start

@media screen and (max-width: 1899px) {
  .is-spider-url-column ul li .MuiToolbar-gutters {
    position: unset;
  }
  .is-spider-url-column ul li [class^="MuiFormControl-root MuiTextField-root MUIDataTableSearch-searchText-"] {
    max-width: 100%;
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 1399px) {
  .is-btn,
  thead.MuiTableHead-root tr th,
  tbody.MuiTableBody-root tr td,
  thead.MuiTableHead-root th span {
    font-size: 14px;
  }
  input,select,.form-control,
  .react-select .select__control,
  .is-spider-batchid h3 {
    height: 32px;
    line-height: 30px;
  }
  .is-head-filter .react-select .select__control {
    height: 30px;
  }
  .is-btn {
      height: 32px;
      line-height: 32px;
      min-width: 100px;
  }
  .infraction-header{
    text-align: center;
    
  }
  .infraction-header h1{
    color: #008FFF;
    height: auto;
  }
  .ind{
    border: 2px solid black;
    box-sizing: border-box;
  }
  .incidentEye{
    cursor: pointer;
  }
  .incident-overvie-form input{
    border-color: #5e5873 !important;
  }
  .ocr input{
    border-color: #5e5873 !important;
  }
  .security-list{
    margin-left: 20px;
  }
  .navTabProfile{
    background-color: whitesmoke;
    height: 20%;
  }
  .changePass{
    margin-left: 30px;
    margin-top: 30px;
    background-color: rgb(255, 255, 255);
  }
  .is-spider-url-column ul li [class^="MuiFormControl-root MuiTextField-root MUIDataTableSearch-searchText-"] {
    max-width: 100%;
  }
  .is-batch-review p {
    font-size: 14px;
    white-space: nowrap;
  }
  .is-spider-url-column ul li .MuiToolbar-gutters {
    position: unset;
  }
  
  .over {
    transform: scale(1.01);
    border: 1px dashed #eee;
  }
  .is-config-row span {
    top: 6px;
  }
  
}
@media screen and (max-width: 1199px) {
  .wrapper .content,
  .wrapper .vertical-layout.vertical-menu-modern.menu-collapsed .app-content,
  .vertical-layout.vertical-menu-modern.menu-collapsed .footer  {
      margin-left: 0!important;
  }
  .is-toggle,.is-sidebar-menuicon {
    display: none;
  }
  .is-toggle-open .main-menu .main-menu-content {
    opacity: 1;
  }
  .main-menu .shadow-bottom {
    margin: 0;
    background: none;
  }
  html[dir] .navbar-sticky .app-content {
    padding: calc(0rem + 4.3rem) 1rem 0 0rem;
  }
}

@media screen and (max-width: 991px) {
  nav.header-navbar .is-head-search {
    max-width: 210px;
  }
  nav.header-navbar .is-head-client-name h2 {
    font-size: 13px;
  }

  .dropdown-user span.is-head-user-icon {
    margin-right: 5px;
  }
  .dropdown-user span.is-head-user-icon svg {
    width: 20px;
    
  }
  nav.header-navbar .is-head-logo img {
    width: 130px;
}
}
// responsive end
