@font-face {
    font-family: 'proxima_nova';
    src: url('../fonts/proxima_nova.woff2') format('woff2'),
         url('../fonts/proxima_nova.woff') format('woff'),
         url('../fonts/proximanova-regular-webfont.svg#proxima_novaregular') format('svg');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_nova';
    src: url('../fonts/proxima_nova_seb_font-webfont.woff2') format('woff2'),
         url('../fonts/proxima_nova_seb_font-webfont.woff') format('woff'),
         url('../fonts/proxima_nova_seb_font-webfont.svg#proxima_novasemibold') format('svg');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_nova';
    src: url('../fonts/proxima_nova_bold_font-webfont.woff2') format('woff2'),
         url('../fonts/proxima_nova_bold_font-webfont.woff') format('woff'),
         url('../fonts/proxima_nova_bold_font-webfont.svg#proxima_novabold') format('svg');
    font-weight: 700;
    font-style: normal;
}